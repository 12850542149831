.root {
  color: var(--color-text);
  background: var(--color-background);
  display: flex;
  align-items: center;
  flex-direction: column;
  height: var(--height-app);
}

.content {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
